<template>
	<div>
		<div class="" style="position: relative;width:100%;height: 100%;">
			<div id="bottomCenter" style="width:100%;height:100%;position: relative;z-index: 9;"></div>
		</div>

	</div>
</template>

<script>
	import * as echarts from 'echarts'
	import 'echarts-gl'
	export default {
		data() {
			return {
				data: []
			}
		},
		mounted() {
			this.$request('/center/home/middleBottomTwo').then(res => {
				this.init(res.data);
			})
		},
		methods: {
			init(pageData) {
				let that = this
				let myChart = echarts.init(document.getElementById("bottomCenter"), "purple-passion");
				//随着屏幕大小调节图表
				window.addEventListener("resize", () => {
					myChart.resize();
				});


				var option = {
					//你的代码
					// backgroundColor: "#000",
					title: {
						show: false
					},
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgba(13,5,30,.6)',
						borderWidth: 1,
						borderColor: '#32A1FF',
						padding: 5,
						textStyle: {
							color: '#fff'
						}
					},
					legend: {
						right: 'right',
						top: 5,
						// icon: 'circle',
						itemWidth: 10,
						itemHeight: 10,
						width: 200,
						// itemGap: 37,
						textStyle: {
							fontSize: 12,
							fontFamily: 'Source Han Sans CN',
							fontWeight: 'normal',
							color: '#FFFFFF'
						}
					},
					grid: {
						x: '5%',
						y: '18%',
						x2: '5%',
						y2: '19%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						axisLabel: {
							color: 'rgba(255, 255, 255, 0.8)',
							fontSize: 9,
							interval: 0,
							// formatter: function(value) {
							// 	var str = "";
							// 	var num = 3; //每行显示字数
							// 	var valLength = value.length; //该项x轴字数
							// 	var rowNum = Math.ceil(valLength / num); // 行数

							// 	if (rowNum > 1) {
							// 		for (var i = 0; i < rowNum; i++) {
							// 			var temp = "";
							// 			var start = i * num;
							// 			var end = start + num;

							// 			temp = value.substring(start, end) + "\n";
							// 			str += temp;
							// 		}
							// 		return str;
							// 	} else {
							// 		return value;
							// 	}
							// },
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#2D4377'
							}
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
							lineStyle: {
								color: '#2D4377',
								type: "dotted",
							}
						},
						data: ['爆品区','协会推荐','欢乐送','冻力双12','火博券']
						// pageData.map(obj => obj.date)单位：个
					},
					yAxis: {
						type: 'value',
						name: "",
						min: 0,
						position: 'left',
						nameTextStyle: {
							color: "#fff",
							fontSize: 12,
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: '#2D4377'
							}
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: '#2D4377',
								type: "dotted",
							}
						},
						axisLabel: {
							formatter: '{value}',
							textStyle: {
								color: "#FFFFFF",
							}
						},
					},
					// dataZoom: [
					//     {
					//         // x轴没有具体的数值
					//         type: 'inside', // 图形内部的滚动条
					//         xAxisIndex: [0], // 明确指定滚动的轴
					//         minValueSpan: 1, // x轴最小的index
					//         maxValueSpan: 3, // x轴最大的index
					//         zoomOnMouseWheel: false // 关闭鼠标滚轮缩放
					//     }
					// ],
					series: [{
							name: '营销额(万元)',
							type: 'bar',
							barWidth: 7,
							barGap: '40%',
							itemStyle: {
								borderWidth: 1,
								borderRadius: [3, 3, 0, 0],
								color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [{
										offset: 0,
										color: '#A5ADFF' // 0% 处的颜色
									}, {
										offset: 1,
										color: '#9B69EC' // 100% 处的颜色
									}]
								}
							},
							data: [156.8,123.6,25.3,18.2,37]
							// pageData.map(obj => obj.zhnyCount)
						}, {
							name: '服务次数(万次)',
							type: 'bar',
							barWidth: 7,
							barGap: '40%',
							itemStyle: {
								borderWidth: 1,
								borderRadius: [3, 3, 0, 0],
								color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [{
										offset: 0,
										color: '#E1B031' // 0% 处的颜色
									}, {
										offset: 1,
										color: '#FF8143' // 100% 处的颜色
									}]
								}
							},
							data: [89.1,103.5,0.8,0.6,0.7]
							// pageData.map(obj => obj.zzfnCount)
						}, 
						// {
						// 	name: '服务业',
						// 	type: 'bar',
						// 	barWidth: 7,
						// 	barGap: '40%',
						// 	itemStyle: {
						// 		borderWidth: 1,
						// 		borderRadius: [3, 3, 0, 0],
						// 		color: {
						// 			type: 'linear',
						// 			x: 0,
						// 			y: 0,
						// 			x2: 0,
						// 			y2: 1,
						// 			colorStops: [{
						// 				offset: 0,
						// 				color: '#00BEFF' // 0% 处的颜色
						// 			}, {
						// 				offset: 1,
						// 				color: '#247FFF' // 100% 处的颜色
						// 			}]
						// 		}
						// 	},
						// 	data: pageData.map(obj => obj.fwycount)
						// },
						// {
						// 	name: '其他',
						// 	type: 'bar',
						// 	barWidth: 14,
						// 	barGap: '40%',
						// 	itemStyle: {
						// 		borderWidth: 1,
						// 		borderRadius: [3, 3, 0, 0],
						// 		color: {
						// 			type: 'linear',
						// 			x: 0,
						// 			y: 0,
						// 			x2: 0,
						// 			y2: 1,
						// 			colorStops: [{
						// 				offset: 0,
						// 				color: '#00DE92' // 0% 处的颜色
						// 			}, {
						// 				offset: 1,
						// 				color: '#69FFEF' // 100% 处的颜色
						// 			}]
						// 		}
						// 	},
						// 	data: [20, 96, 140, 150, 402, 20, 96, 140, 150]
						// },
					]
				};


				myChart.setOption(option);
				setInterval(function() {
					myChart.clear()
					myChart.setOption(option);
				}, 20000)
				var app = {
					currentIndex: -1,
				};
				setInterval(function() {
					var dataLen = option.series[0].data.length;

					// 取消之前高亮的图形
					myChart.dispatchAction({
						type: 'downplay',
						seriesIndex: 0,
						dataIndex: app.currentIndex
					});
					app.currentIndex = (app.currentIndex + 1) % dataLen;
					//console.log(app.currentIndex);
					// 高亮当前图形
					myChart.dispatchAction({
						type: 'highlight',
						seriesIndex: 0,
						dataIndex: app.currentIndex,
					});
					// 显示 tooltip
					myChart.dispatchAction({
						type: 'showTip',
						seriesIndex: 0,
						dataIndex: app.currentIndex
					});
				}, 2000);

			}
		}
	}
</script>

<style scoped>
	.sycw {
		font-size: 72rpx;
		font-family: DingTalk JinBuTi, DingTalk JinBuTi;
		font-weight: 900;
		color: #FFFFFF;
		text-shadow: 0px 8rpx 20rpx rgba(0, 0, 0, 0.8);
		-webkit-background-clip: text;
		-webkit-text-fill-color: #ffedf6;
	}

	.title {
		font-size: 24rpx;
		font-family: Source Han Sans, Source Han Sans;
		font-weight: 400;
		color: #dbecff;
		line-height: 40px;
		margin-left: 20rpx;
	}
</style>