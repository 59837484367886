import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import login from '../views/login/login.vue'

Vue.use(VueRouter)

const routes = [{
	path: '/login',
	name: 'login',
	component: login,
}, {
	path: '/',
	name: 'HomeView',
	component: HomeView,
}]

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router