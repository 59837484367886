<template>
	<div class="bg1 flex justc alit" style="position: absolute;width: 100%;height: 100%;">

		<div class="login-box">
			<div class="flex alit justc">
				<img src="https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/hotpotNew/coupon/favicon.png"
					style="width: 40px;height: 40px;border-radius: 50%;margin-right: 10px;" alt="" />
				<p class="form-title">重庆火锅产业数字大脑</p>
			</div>
			<form>
				<div class="user-box">
					<input v-model="usemame" type="text" name="" required="">
					<label>用户名</label>
				</div>
				<div class="user-box">
					<input v-model="password" type="password" name="" required="">
					<label>密码</label>
				</div>
				<center>
					<a href="#" @click="login">
						登录
						<span></span>
					</a>
				</center>
			</form>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				inputPassword: 'password',
				usemame: '',
				password: ''
			}
		},
		methods: {
			login() {
				if (this.usemame != 'huoguogo') {
					this.$message.error('账号不存在,请重新输入');
					return;
				}
				if (this.password != 'go666666') {
					this.$message.error('密码错误');
					return;
				}
				sessionStorage.setItem('log', 'log')
				this.$router.replace('/')
			}
		},
	}
</script>

<style>
	.login-box {
		position: absolute;
		top: 45%;
		left: 50%;
		width: 450px;
		padding: 20px;
		transform: translate(-50%, -50%);
		background: rgba(0, 33, 77, 0.15);
		box-sizing: border-box;
		box-shadow: 0 15px 25px rgba(103, 98, 166, 0.6);
		border-radius: 10px;
	}

	.login-box .user-box {
		position: relative;
	}

	.login-box .user-box input {
		width: 100%;
		padding: 10px 0;
		font-size: 16px;
		color: #fff;
		margin-bottom: 30px;
		border: none;
		border-bottom: 1px solid #fff;
		outline: none;
		background: transparent;
	}

	.login-box .user-box label {
		position: absolute;
		top: 0;
		left: 0;
		padding: 10px 0;
		font-size: 16px;
		color: #fff;
		pointer-events: none;
		transition: .5s;
	}

	.login-box .user-box input:focus~label,
	.login-box .user-box input:valid~label {
		top: -20px;
		left: 0;
		color: #bdb8b8;
		font-size: 12px;
	}

	.login-box form a {
		position: relative;
		display: inline-block;
		padding: 10px 40px;
		color: #ffffff;
		font-size: 16px;
		text-decoration: none;
		text-transform: uppercase;
		overflow: hidden;
		transition: .5s;
		margin-top: 40px;
		letter-spacing: 4px
	}

	.login-box a:hover {
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/rabbit/259d4f85053fbcc39e7e1260873d40c.png');
		background-size: 100% 100%;
		/* background: #03f40f; */
		color: #fff;
		border-radius: 5px;
		/*  box-shadow: 0 0 5px #03f40f,
	              0 0 25px #03f40f,
	              0 0 50px #03f40f,
	              0 0 100px #03f40f; */
	}

	.login-box a span {
		position: absolute;
		display: block;
	}

	@keyframes btn-anim1 {
		0% {
			left: -100%;
		}

		50%,
		100% {
			left: 100%;
		}
	}

	.login-box a span:nth-child(1) {
		bottom: 2px;
		left: -100%;
		width: 100%;
		height: 2px;
		background: linear-gradient(90deg, transparent, #03f40f);
		animation: btn-anim1 2s linear infinite;
	}

	.form-title {
		font-size: 24px;
		line-height: 16px;
		font-weight: 600;
		text-align: center;
		color: #fff;
	}

	/* .form {
		background-color: #fff;
		display: block;
		padding: 20px;
		border-radius: 15px;
		box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
	}

	.form-title {
		font-size: 24px;
		line-height: 16px;
		font-weight: 600;
		text-align: center;
		color: #000;
	}

	.input-container {
		position: relative;
	}

	.input-container input,
	.form button {
		outline: none;
		border: 1px solid #e5e7eb;
		margin: 8px 0;
	}

	.input-container input {
		background-color: #fff;
		padding: 10px;
		padding-right: 3rem;
		font-size: 16px;
		line-height: 30px;
		width: 200px;
		border-radius: 10px;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	}

	.input-container span {
		display: grid;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		padding-right: 20px;
		place-content: center;
		cursor: pointer;
	}

	.input-container span svg {
		color: #9CA3AF;
		width: 20px;
		height: 20px;
	}

	.submit {
		display: block;
		background-color: #4F46E5;
		color: #ffffff;
		font-size: 18px;
		line-height: 46px;
		font-weight: 500;
		width: 100%;
		border-radius: 0.5rem;
		text-transform: uppercase;
	}

	.signup-link {
		color: #6B7280;
		font-size: 0.875rem;
		line-height: 1.25rem;
		text-align: center;
	}

	.signup-link a {
		text-decoration: underline;
	} */


	.bg1 {
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/rabbit/newHome/bg.gif');
		background-size: 90vw 100%;
		background-position: 5vw 0;
	}
</style>