<template>
	<div class="cont flexCol alit">

		<el-dialog title="提示" :visible.sync="dialogVisible" width="80%" top="1%" :append-to-body="true">
			<span slot="title"></span>
			<div class="mySeamlessScrollStyle" v-if="rightTopScreen">
				<div style="position: absolute;top: 4px;left: 45%;color: #FFFFFF;font-size: 22px;font-weight: bold;">
					实时交易数据</div>
				<table class="customTable">
					<thead>
						<tr class="trtdstyle">
							<th>下单账户</th>
							<th>公司名称</th>
							<th>产品名称</th>
							<th>订单金额</th>
							<th>商品数量</th>
							<!-- <th>订单状态</th> -->
							<th>下单时间</th>
						</tr>
					</thead>
				</table>
				<vue-seamless-scroll :data="rightTopScreen" :class-option="scrollOption" class="scrollStyle">
					<!-- 里面的内容完全自定义 -->
					<table class="customTable">
						<tbody>
							<tr v-for="(item, index) in rightTopScreen" :key="index" style="display:flex;height: 70px;"
								class="trtdstyle" @click="handleButton(item)">
								<td class="textOne" style="height: 5px;">
									<!-- {{item.shopName ? item.shopName : item.nickname}} -->
									{{changeShowText(item.shopName ? item.shopName : item.nickname)}}
								</td>
								<td class="textOne" style="font-weight: bold;height: 5px;">
									{{changeShowText(item.shopName)}}
								</td>
								<td style="" class="textOne">{{item.spuName}}
								</td>
								<td style="">{{item.payPrice / 100}}</td>
								<td style="">{{item.num}}</td>
								<td style="">{{item.formattedCreateTime}}</td>
							</tr>
						</tbody>
					</table>
				</vue-seamless-scroll>
			</div>
			<span slot="footer"></span>
		</el-dialog>

		<el-dialog title="提示" :visible.sync="dialogVisible2" width="80%" top="1%" :append-to-body="true">
			<span slot="title"></span>
			<div class="mySeamlessScrollStyle">
				<div style="position: absolute;top: 4px;left: 45%;color: #FFFFFF;font-size: 22px;font-weight: bold;">
					入驻企业</div>
				<table class="customTable">
					<thead>
						<tr class="trtdstyle">
							<th>企业名称</th>
							<th> 企业类型</th>
							<th>入驻时间</th>
						</tr>
					</thead>
				</table>
				<vue-seamless-scroll :data="shopListInfo" :class-option="scrollOption" class="scrollStyle">
					<!-- 里面的内容完全自定义 -->
					<table class="customTable">
						<tbody>
							<tr v-for="(item, index) in shopListInfo" :key="index" style="display:flex;height: 70px;"
								class="trtdstyle" @click="handleButton(item)">
								<td class="textOne" style="height: 5px;">
									{{changeShowText(item.enterpriseName)}}

								</td>
								<td class="textOne" style="font-weight: bold;height: 5px;">{{item.tradeIdentity}}
								</td>
								<td style="" class="textOne">{{utils.changeTime(item.createTime) }}
								</td>
							</tr>
						</tbody>
					</table>
				</vue-seamless-scroll>
			</div>
			<span slot="footer"></span>
		</el-dialog>

		<el-dialog title="提示" :visible.sync="dialogVisible3" width="80%" top="1%" :append-to-body="true">
			<span slot="title"></span>
			<div class="mySeamlessScrollStyle" style="height: 62vh;overflow: hidden;">
				<div style="position: absolute;top: 4px;left: 45%;color: #FFFFFF;font-size: 22px;font-weight: bold;">
					园区新公司</div>
				<div class="flex" style="flex: 1;">
					<div style="width: 100%;">
						<table class="customTable">
							<thead>
								<tr class="trtdstyle">
									<th>原公司</th>
									<th>新公司 </th>
								</tr>
							</thead>
						</table>
						<vue-seamless-scroll :data="oldShop" :class-option="scrollOption" class="scrollStyle">
							<table class="customTable">
								<tbody>
									<tr v-for="(item, index) in oldShop" :key="index"
										style="text-align: center;height: 70px;" class="trtdstyle flex justc">
										<td class="textOne">
											<!-- {{item}} -->
											{{changeShowText(item)}}
										</td>
										<td class="textOne">
											{{changeShowText(newShop[index])}}  <a style="font-size: 13px;margin-left: 5px;">(渝中区)</a>
										</td>
									</tr>
								</tbody>
							</table>
						</vue-seamless-scroll>
					</div>
					<!-- <div style="width: 50%;">
						<table class="customTable">
							<thead>
								<tr class="trtdstyle">
									<th>原公司</th>
								</tr>
							</thead>
						</table>
						<table class="customTable">
							<tbody>
								<tr v-for="(item, index) in oldShop" :key="index" style="text-align: center;"
									class="trtdstyle flex justc">
									<div class="textOne" style="height: 40px;line-height: 40px;">
										{{item}}
									</div>
								</tr>
							</tbody>
						</table>
					</div> -->
				</div>
			</div>
			<span slot="footer"></span>
		</el-dialog>

		<div class="top-title"></div>
		<div class="top-count flex justc">
			<div class="flex alit">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/%E6%9C%8D%E5%8A%A1%E4%BA%BA%E6%95%B0.png"
					style="width: 68px;height: 68px;margin-right: 10px;" alt="" />
				<div class="" style="width: 8vw;">
					<div class="flex">
						<!-- <div class="top-count-num">{{topData.fwrsLeft}}</div> -->
						<!-- <countTo ref="countTo1" v-if="todayStatistics.servedPersonTimes" :startVal='0'
							:endVal='new Number(todayStatistics.servedPersonTimes).valueOf()' separator=""
							:duration='3000' class="top-count-num"></countTo>
						<div class="top-count-desc">次/{{Math.ceil(allStatistics.servedPersonTimes)}}次</div> -->
						<countTo ref="countTo1" :startVal='0' :endVal='1' separator="" :duration='3000'
							class="top-count-num"></countTo>
						<div class="top-count-desc">万+/373万次</div>
					</div>
					<div class="top-count-title">服务人数</div>
				</div>
			</div>
			<div class="flex alit" style="margin: 0 0;" @click="dialogVisible2 = true">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/%E4%BA%A4%E6%98%93%E7%AC%94%E6%95%B0.png"
					style="width: 68px;height: 68px;margin-right: 10px;" alt="" />
				<div class="" style="width: 5vw;">
					<div class="flex">
						<!-- <div class="top-count-num">{{topData.jybsLeft}}</div> -->
						<countTo ref="countTo2" :startVal='0' :endVal='435' separator="" :duration='3000'
							class="top-count-num"></countTo>
						<div class="top-count-desc">家</div>
					</div>
					<div class="top-count-title">入驻企业</div>
				</div>
			</div>

			<!-- <div class="flex alit" style="margin: 0 0;" @click="dialogVisible = true">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/%E4%BA%A4%E6%98%93%E7%AC%94%E6%95%B0.png"
					style="width: 68px;height: 68px;margin-right: 10px;" alt="" />
				<div class="" style="width: 7vw;">
					<div class="flex">
						<countTo ref="countTo2"  :startVal='0'
							:endVal='todayStatistics.numberOfTransactions' separator="" :duration='3000'
							class="top-count-num"></countTo>
						<div class="top-count-desc">次/{{Math.ceil(allStatistics.numberOfTransactions)}}次</div>
					</div>
					<div class="top-count-title">交易笔数</div>
				</div>
			</div> -->
			<div class="flex alit" @click="dialogVisible = true">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/%E4%BA%A4%E6%98%93%E9%87%91%E9%A2%9D.png"
					style="width: 68px;height: 68px;margin-right: 10px;" alt="" />
				<div class="" style="width: 6vw;">
					<div class="flex">
						<!-- <div class="top-count-num">{{Math.ceil(topData.jyjeLeft/10000)}}</div> decimals="1"-->
						<countTo ref="countTo3" :startVal='0' :endVal='1031' separator="" :duration='3000'
							class="top-count-num"></countTo>
						<div class="top-count-desc">万元
						</div>
					</div>
					<div class="top-count-title">交易金额</div>
				</div>
			</div>
			<!-- 	<div class="flex alit">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/%E4%BA%A4%E6%98%93%E9%87%91%E9%A2%9D.png"
					style="width: 68px;height: 68px;margin-right: 10px;" alt="" />
				<div class="" style="width: 5vw;">
					<div class="flex">
						<countTo ref="countTo3"  :startVal='0'
							:endVal='Math.ceil((todayStatistics.theAmountOfTheTransaction/10000))' separator=""
							:duration='3000' class="top-count-num"></countTo>
						<div class="top-count-desc">万/{{Math.ceil(allStatistics.theAmountOfTheTransaction/10000)}}万
						</div>
					</div>
					<div class="top-count-title">交易金额</div>
				</div>
			</div> -->
			<div class="flex alit" style="margin: 0 0;">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/%E4%BA%A4%E6%98%93%E9%87%8D%E9%87%8F.png"
					style="width: 68px;height: 68px;margin-right: 10px;" alt="" />
				<div class="" style="width: 6vw;">
					<div class="flex">
						<!-- <div class="top-count-num">{{topData.jyzlLeft}}</div> -->
						<countTo ref="countTo4" :startVal='0' :endVal='367' separator="" :duration='3000'
							class="top-count-num"></countTo>
						<div class="top-count-desc">
							家</div>
					</div>
					<div class="top-count-title">火锅门店</div>
				</div>
			</div>
			<!-- 	<div class="flex alit" style="margin: 0 0;">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/%E4%BA%A4%E6%98%93%E9%87%8D%E9%87%8F.png"
					style="width: 68px;height: 68px;margin-right: 10px;" alt="" />
				<div class="" style="width: 6vw;">
					<div class="flex">
						<countTo ref="countTo4"  :startVal='0'
							:endVal='Math.ceil(kgToTons1(todayStatistics.transactionWeight))' separator=""
							:duration='3000' class="top-count-num"></countTo>
						<div class="top-count-desc">
							吨/{{ Math.ceil(kgToTons1(allStatistics.transactionWeight).toFixed(1))}}吨</div>
					</div>
					<div class="top-count-title">交易重量</div>
				</div>
			</div> -->
			<div class="flex alit">
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/%E5%95%86%E5%93%81%E6%BA%AF%E6%BA%90.png"
					style="width: 68px;height: 68px;margin-right: 10px;" alt="" />
				<div class="" style="width: 7vw;">
					<div class="flex">
						<!-- <div class="top-count-num">{{topData.spsyLeft}}</div> -->
						<!-- <countTo ref="countTo5" v-if="topData.spsyLeft" :startVal='0' :endVal='topData.spsyLeft'
							separator="" :duration='3000' class="top-count-num"></countTo>
						<div class="top-count-desc">个/{{(topData.spsyRight)}}个</div> -->

						<!-- <countTo ref="countTo5" v-if="topData.spsyLeft" :startVal='0' :endVal='16' separator=""
							:duration='3000' class="top-count-num"></countTo>
						<div class="top-count-desc">个/860个</div> -->
						<countTo ref="countTo5" :startVal='0' :endVal='30' separator="" :duration='3000'
							class="top-count-num"></countTo>
						<div class="top-count-desc">万个+</div>
					</div>
					<div class="top-count-title">商品溯源</div>
				</div>
			</div>
		</div>
		<!-- 	<canvas id="canvas"></canvas>
		<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/%E5%95%86%E5%93%81%E6%BA%AF%E6%BA%90.png" alt="" /> -->
		<!-- <finance style="width: 260px;height: 260px;"></finance> -->
		<div class="centerbg flexCol alit justc" style="height: 100vh;">
			<img :src="'https://xlqy.obs.cn-southwest-2.myhuaweicloud.com/huoguodizuo2.gif?v='+nowTime" alt=""
				style="width: 110vw;height: 110vh;position: absolute;margin-top: -20vh;" />
			<center-map style="width: 100%;height: 600px;position: absolute;z-index: 2;margin-top:-10%;"></center-map>
		</div>

		<div class="left flexCol">
			<!-- <div class="title">&nbsp;&nbsp;&nbsp;&nbsp;产业概况</div> -->
			<img class="title"
				src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/title/title1%402x.png" alt="" />
			<div class="left-style flex justc" style="flex-wrap: wrap;height: 21vh;">
				<div class="left-one-list" style="margin-top: 1%;">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/dizuo1.png"
						style="position: absolute;bottom: 0;width: 100%;height: 100%;" alt="" />
					<div class="flex justc" style="color: #FFC300;text-align: center;margin-top: 5px;">
						<countTo ref="countTo6" v-if="leftOne.qqfbLeft" :startVal='0' :endVal='30' separator=""
							:duration='3000' style="font-size: 20px;"></countTo>
						<!-- <div class="" style="font-size: 20px;">{{leftOne.qqfbLeft}}</div> -->
						<div class="" style="font-size: 14px;margin-top: 3px;">/</div>
						<!-- {{leftOne.qqfbRight}} -->
						<countTo ref="countTo7" v-if="leftOne.qqfbRight" :startVal='0' :endVal='leftOne.qqfbRight'
							separator="" :duration='3000' style="font-size: 14px;margin-top: 3px;"></countTo>
					</div>
					<div class="left-one-list-title">全球分布</div>
				</div>
				<div class="left-one-list" style="margin: 1% 5px 0 5px;">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/dizuo1.png"
						style="position: absolute;bottom: 0;width: 100%;height: 100%;" alt="" />
					<div class="flex justc" style="color: #FFC300;text-align: center;margin-top: 5px;">
						<!-- <div class="" style="font-size: 20px;">{{Math.ceil(leftOne.qghgCount / 10000)}}</div> -->
						<countTo ref="countTo8" v-if="leftOne.qghgCount" :startVal='0'
							:endVal='Math.ceil(leftOne.qghgCount / 10000)' separator="" :duration='3000'
							style="font-size: 20px;"></countTo>
						<div class="" style="font-size: 14px;margin-top: 3px;">/万家</div>
					</div>
					<div class="left-one-list-title">全国火锅</div>
				</div>
				<div class="left-one-list" style="margin-top: 1%;">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/dizuo1.png"
						style="position: absolute;bottom: 0;width: 100%;height: 100%;" alt="" />
					<div class="flex justc" style="color: #FFC300;text-align: center;margin-top: 5px;">
						<!-- <div class="" style="font-size: 20px;">{{Math.ceil(leftOne.cqhgCount/ 10000)}}</div> -->
						<countTo ref="countTo9" v-if="leftOne.cqhgCount" :startVal='0'
							:endVal='Math.ceil(leftOne.cqhgCount / 10000)' separator="" :duration='3000'
							style="font-size: 20px;"></countTo>
						<div class="" style="font-size: 14px;margin-top: 3px;">万家</div>
					</div>
					<div class="left-one-list-title">重庆火锅</div>
				</div>
				<div class="left-one-list" style="margin-top: 1%;">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/dizuo22.png"
						style="position: absolute;bottom: 0;width: 100%;height: 100%;" alt="" />
					<div class="flex justc" style="color: #FFC300;text-align: center;margin-top: 10px;">
						<!-- <div class="" style="font-size: 20px;">{{Math.ceil(leftOne.qsqyCount/ 10000)}}</div> -->
						<countTo ref="countTo11" v-if="leftOne.qsqyCount" :startVal='0' :endVal='1.7' separator=""
							:duration='3000' :decimals="1" style="font-size: 20px;"></countTo>
						<div class="" style="font-size: 14px;margin-top: 3px;">万家</div>
					</div>
					<div class="left-one-list-title">全市企业</div>
				</div>
				<div class="left-one-list" style="margin: 1% 5px 0 5px;">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/dizuo1.png"
						style="position: absolute;bottom: 0;width: 100%;height: 100%;" alt="" />
					<div class="flex justc" style="color: #FFC300;text-align: center;margin-top: 5px;">
						<!-- <div class="" style="font-size: 20px;">{{Math.ceil(leftOne.qsmdCount/ 10000)}}</div> -->
						<countTo ref="countTo10" v-if="leftOne.qsmdCount" :startVal='0' :decimals="1" :endVal='3.6'
							separator="" :duration='3000' style="font-size: 20px;"></countTo>
						<div class="" style="font-size: 14px;margin-top: 3px;">万家</div>
					</div>
					<div class="left-one-list-title">全市门店</div>
				</div>
				<div class="left-one-list" style="margin: 1% 5px 0 5px;">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/dizuo22.png"
						style="position: absolute;bottom: 0;width: 100%;height: 100%;" alt="" />
					<div class="flex justc" style="color: #FFC300;text-align: center;margin-top: 10px;">
						<!-- <div class="" style="font-size: 20px;">{{leftOne.hyqyCount}}</div> -->
						<countTo ref="countTo12" v-if="leftOne.hyqyCount" :startVal='0' :endVal='1750' separator=""
							:duration='3000' style="font-size: 20px;"></countTo>
						<div class="" style="font-size: 14px;margin-top: 3px;">家</div>
					</div>
					<div class="left-one-list-title">会员企业</div>
				</div>
				<div class="left-one-list" style="margin-top: 1%;">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/dizuo22.png"
						style="position: absolute;bottom: 0;width: 100%;height: 100%;" alt="" />
					<div class="flex justc" style="color: #FFC300;text-align: center;margin-top: 10px;">
						<!-- <div class="" style="font-size: 20px;">{{Math.ceil(leftOne.cqysMoney/ 100000000)}}</div> -->
						<countTo ref="countTo13" v-if="leftOne.cqysMoney" :startVal='0' :endVal='3000' separator=""
							:duration='3000' style="font-size: 20px;"></countTo>
						<div class="" style="font-size: 14px;margin-top: 3px;">亿+</div>
					</div>
					<div class="left-one-list-title">产业营收</div>
				</div>
				<div class="left-one-list" style="margin: 1% 5px 0 5px;">
					<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/dizuo22.png"
						style="position: absolute;bottom: 0;width: 100%;height: 100%;" alt="" />
					<div class="flex justc" style="color: #FFC300;text-align: center;margin-top: 10px;">
						<!-- <div class="" style="font-size: 20px;">{{Math.ceil(leftOne.jyPersonCount/ 10000)}}</div> -->
						<countTo ref="countTo14" v-if="leftOne.jyPersonCount" :startVal='0'
							:endVal='Math.ceil(leftOne.jyPersonCount/ 10000)' separator="" :duration='3000'
							style="font-size: 20px;"></countTo>
						<div class="" style="font-size: 14px;margin-top: 3px;">万</div>
					</div>
					<div class="left-one-list-title">就业人数</div>
				</div>
			</div>
			<img class="title"
				src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/title/%E6%A0%87%E5%87%86%E5%BB%BA%E8%AE%BE.png"
				alt="" />
			<div class="left-style flex justc alit" style="flex-wrap: wrap;height: 12vh;">
				<div class="">
					<div class="flex alit">
						<div class="">
							<!-- <div class="" style="font-size: 20px;color: #0AA7FF;">{{leftTwo.gjStandard}}</div> -->
							<countTo ref="countTo15" v-if="leftTwo.gjStandard" :startVal='0'
								:endVal='leftTwo.gjStandard' separator="" :duration='3000'
								style="font-size: 20px;color: #0AA7FF;"></countTo>
							<div class="" style="font-size: 12px;color: #fff;">国家标准</div>
						</div>
						<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/xian.png"
							style="width: 5px;height: 33px;margin-left: 6px;" alt="" />
					</div>
					<div class="flex alit" style="margin-top: 22px;">
						<div class="">
							<!-- <div class="" style="font-size: 20px;color: #0AA7FF;">{{leftTwo.hyStandard}}</div> -->
							<countTo ref="countTo16" v-if="leftTwo.hyStandard" :startVal='0'
								:endVal='leftTwo.hyStandard' separator="" :duration='3000'
								style="font-size: 20px;color: #0AA7FF;"></countTo>
							<div class="" style="font-size: 12px;color: #fff;">行业标准</div>
						</div>
						<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/xian.png"
							style="width: 5px;height: 33px;margin-left: 6px;" alt="" />
					</div>
				</div>
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/%E6%A0%87%E5%87%86%E5%BB%BA%E8%AE%BE.png"
					style="height: 120px;" alt="" />
				<div class="">
					<div class="flex alit">
						<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/xian.png"
							style="width: 5px;height: 33px;margin-right: 6px;" alt="" />
						<div class="">
							<!-- <div class="" style="font-size: 20px;color: #0AA7FF;">{{leftTwo.scStandard}}</div> -->
							<countTo ref="countTo17" v-if="leftTwo.scStandard" :startVal='0'
								:endVal='leftTwo.scStandard' separator="" :duration='3000'
								style="font-size: 20px;color: #0AA7FF;"></countTo>
							<div class="" style="font-size: 12px;color: #fff;">食材标准</div>
						</div>

					</div>
					<div class="flex alit" style="margin-top: 22px;">
						<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/xian.png"
							style="width: 5px;height: 33px;margin-right: 6px;" alt="" />
						<div class="">
							<!-- <div class="" style="font-size: 20px;color: #0AA7FF;">{{leftTwo.zrStandard}}</div> -->
							<countTo ref="countTo18" v-if="leftTwo.zrStandard" :startVal='0'
								:endVal='leftTwo.zrStandard' separator="" :duration='3000'
								style="font-size: 20px;color: #0AA7FF;"></countTo>
							<div class="" style="font-size: 12px;color: #fff;">准入标准</div>
						</div>
					</div>
				</div>
			</div>
			<img class="title"
				src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/title/%E4%BE%9B%E5%BA%94%E9%93%BE.png"
				alt="" />

			<div class="left-style flex justc alit" style="flex-wrap: wrap;height: 12.5vh;">
				<div class="flex justc alit" style="width: 100%;">
					<div class="flex alit" style="width: 40%;">
						<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/%E4%B8%80%E5%8E%BF%E4%B8%80%E5%93%81%402x.png"
							style="width: 60px;height: 60px;margin-right: 10px;" alt="" />
						<div class="">
							<div class="flex justc" style="color: #0AA7FF;text-align: center;">
								<!-- <div class="" style="font-size: 20px;">{{leftThree.yxypLeft}}</div> -->
								<countTo ref="countTo19" v-if="leftThree.yxypLeft" :startVal='0' :endVal='12'
									separator="" :duration='3000' style="font-size: 20px;">
								</countTo>
								<div class="" style="font-size: 14px;margin-top: 6px;">/</div>
								<!-- {{leftThree.yxypRight}} -->
								<countTo ref="countTo20" v-if="leftThree.yxypRight" :startVal='0'
									:endVal='leftThree.yxypRight' separator="" :duration='3000'
									style="font-size: 14px;margin-top: 6px;"></countTo>
							</div>
							<div class="" style="font-size: 12px;color: #fff;">一县一品</div>
						</div>
					</div>
					<div class="flex alit" style="margin-left: 30px;width: 40%;">
						<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/%E7%94%9F%E4%BA%A7%E5%88%B6%E9%80%A0%402x.png"
							style="width: 60px;height: 60px;margin-right: 10px;" alt="" />
						<div class="">
							<div class="flex justc" style="color: #0AA7FF;text-align: center;">
								<!-- <div class="" style="font-size: 20px;">{{leftThree.sczzCount}}</div> -->
								<countTo ref="countTo21" v-if="leftThree.sczzCount" :startVal='0'
									:endVal='leftThree.sczzCount' separator="" :duration='3000'
									style="font-size: 20px;"></countTo>
								<!-- <div class="" style="font-size: 14px;margin-top: 6px;">/200</div> -->
							</div>
							<div class="" style="font-size: 12px;color: #fff;">生产制造</div>
						</div>
					</div>
				</div>
				<div class="flex justc alit" style="width: 100%;">
					<div class="flex alit" style="width: 40%;">
						<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/%E4%BA%A7%E5%9C%B0%E7%9B%B4%E4%BE%9B%402x.png"
							style="width: 60px;height: 60px;margin-right: 10px;" alt="" />
						<div class="">
							<div class="flex justc" style="color: #0AA7FF;text-align: center;">
								<!-- 	<div class="" style="font-size: 20px;">{{leftThree.cdzgLeft}}</div>
								<div class="" style="font-size: 14px;margin-top: 6px;">/{{leftThree.cdzgRight}}</div>
								 -->
								<countTo ref="countTo22" v-if="leftThree.cdzgLeft" :startVal='0' :endVal='36'
									separator="" :duration='3000' style="font-size: 20px;">
								</countTo>
								<div class="" style="font-size: 14px;margin-top: 6px;">/</div>
								<!-- {{leftThree.yxypRight}} -->
								<countTo ref="countTo23" v-if="leftThree.cdzgRight" :startVal='0' :endVal='100'
									separator="" :duration='3000' style="font-size: 14px;margin-top: 6px;"></countTo>

							</div>
							<div class="" style="font-size: 12px;color: #fff;">产地直供</div>
						</div>
					</div>
					<div class="flex alit" style="width: 40%;margin-left: 30px;">
						<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/%E5%89%8D%E7%BD%AE%E4%BB%93%E5%82%A8%402x.png"
							style="width: 60px;height: 60px;margin-right: 10px;" alt="" />
						<div class="">
							<div class="flex justc" style="color: #0AA7FF;text-align: center;">
								<!-- <div class="" style="font-size: 20px;">{{leftThree.czccCount}}</div> -->
								<countTo ref="countTo24" v-if="leftThree.czccCount" :startVal='0' :endVal='3'
									separator="" :duration='3000' style="font-size: 20px;"></countTo>
								<!-- <div class="" style="font-size: 14px;margin-top: 6px;">/万吨</div> -->
							</div>
							<div class="" style="font-size: 12px;color: #fff;">前置仓储</div>
						</div>
					</div>
				</div>
			</div>
			<img class="title"
				src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/title/%E5%8E%9F%E4%BA%A7%E5%9C%B0.png"
				alt="" />
			<div class="left-style flex justc alit" style="flex-wrap: wrap;height: 15vh;">
				<div class="left-bottom flex alit justc">
					<div class="left-bottom-title">辣椒基地</div>
					<div class="flex">
						<!-- <div class="left-bottom-num">{{utils.formatCurrency2(leftFour.ljBase)}}</div> -->
						<countTo ref="countTo25" v-if="leftFour.ljBase" :startVal='0' :endVal='370000' :duration='3000'
							class="left-bottom-num"></countTo>
						<div class="left-bottom-mu">亩+</div>
					</div>
				</div>
				<div class="left-bottom flex alit justc" style="margin-left: 10px;">
					<div class="left-bottom-title">花椒基地</div>
					<div class="flex">
						<!-- <div class="left-bottom-num">{{utils.formatCurrency2(leftFour.hjBase)}}</div> -->
						<countTo ref="countTo26" v-if="leftFour.hjBase" :startVal='0' :endVal='260000' :duration='3000'
							class="left-bottom-num"></countTo>
						<div class="left-bottom-mu">亩+</div>
					</div>
				</div>
				<div class="left-bottom flex alit justc" style="margin-top: 10px;">
					<div class="left-bottom-title">牛副产地</div>
					<div class="flex">
						<!-- <div class="left-bottom-num">{{utils.formatCurrency2(leftFour.nfBase)}}</div> -->
						<countTo ref="countTo27" v-if="leftFour.nfBase" :startVal='0' :endVal='230000' :duration='3000'
							class="left-bottom-num"></countTo>
						<div class="left-bottom-mu">吨+</div>
					</div>
				</div>
				<div class="left-bottom flex alit justc" style="margin-left: 10px;margin-top: 10px;">
					<div class="left-bottom-title">海产基地</div>
					<div class="flex">
						<!-- <div class="left-bottom-num">{{utils.formatCurrency2(leftFour.hcBase)}}</div> -->
						<countTo ref="countTo28" v-if="leftFour.hcBase" :startVal='0' :endVal='110000' :duration='3000'
							class="left-bottom-num"></countTo>
						<div class="left-bottom-mu">吨+</div>
					</div>
				</div>
			</div>
		</div>
		<div class="right flexCol">
			<div class="flex alit">
				<img class="title"
					src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/title/%E9%A3%9F%E5%93%81%E5%AE%89%E5%85%A8.png"
					alt="" v-if="rightTopIndex == 1" />
				<img class="title"
					src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/title/b80961f52f8473a1aecf8eb7bce9569.png"
					alt="" v-if="rightTopIndex == 2" />
				<div class="flex alit" style="margin-left: -220px;font-size: 14px;color: #8AD4FF;width: 180px;"
					v-if="rightTopIndex == 1">整体检测报告完整率： <div class="" style="color: #FFC300;">
						{{rightTop.finishRate * 100}}%
					</div>
				</div>
				<div class="" style="margin-left: -220px;font-size: 14px;color: #8AD4FF;width: 180px;" v-else>

				</div>
				<img src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/%E5%88%87%E6%8D%A2%20%281%29.png"
					style="width: 20px;height: 20px;margin-left: 0px;cursor: pointer;"
					@click="rightTopIndex=  rightTopIndex == 1 ? 2 : 1" alt="" />
			</div>

			<div class="right-style flex justc" style="flex-wrap: wrap;height: 26vh;">
				<right-top ref="rightTop" style="width: 100%;height: 100%;" v-if="rightTopIndex == 1"></right-top>
				<div class=""></div>
				<div class="" v-if="rightTopIndex == 2">
					<div class="flex" style="width: 20vw;border-bottom: 1px solid #b8d3f1;padding-bottom: 5px;">
						<div class="" style="width: 20%;color: #fff;text-align: center;">采购商</div>
						<div class="" style="width: 30%;color: #fff;text-align: center;">产品</div>
						<div class="" style="width: 10%;color: #fff;text-align: center;">数量</div>
						<div class="" style="width: 20%;color: #fff;text-align: center;">金额</div>
						<div class="" style="width: 20%;color: #fff;text-align: center;">时间</div>
					</div>
					<div class="" style="height: 24vh;overflow: hidden;">
						<vue-seamless-scroll :data="rightTopScreen" :class-option="scrollOption2" class="scrollStyle">
							<!-- 里面的内容完全自定义 -->
							<table class="customTable">
								<tbody>
									<tr v-for="(item, index) in rightTopScreen" :key="index"
										style="display:flex;height: 40px;" @click="handleButton(item)">
										<!-- <div class="flex alit" style="height: 50px;">
										<div class="flexCol alit justc" style="height: 40px;background: rgb(20, 187, 130);border-radius: 30px;">
											<div class="scroll-left">xxx企业企下单企业*1，金额xxxx元</div>
										</div>
										<div class="flexCol alit justc" style="height: 50px;">
											<div class="scroll-time">2024-12-18 14:42</div>
										</div>
									</div> -->
										<td class="flex alit"
											style="width: 20vw;margin-left: -2px;border-bottom: 1px solid rgba(255, 255, 255, 0.4);">
											<div class="textOne" style="width: 20%;color: #fff;text-align: center;">
												{{item.shopName ? item.shopName : item.nickname}}
											</div>
											<div class="textOne" style="width: 30%;color: #fff;text-align: center;">
												{{item.spuName}}
											</div>
											<div class="" style="width: 10%;color: #fff;text-align: center;">
												{{item.num}}
											</div>
											<div class="" style="width: 20%;color: #fff;text-align: center;">
												{{item.payPrice / 100}}
											</div>
											<div class="" style="width: 20%;color: #fff;text-align: center;">
												{{item.formattedCreateTime}}
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</vue-seamless-scroll>
					</div>
				</div>
			</div>
			<img class="title"
				src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/title/%E4%BA%A7%E5%93%81%E4%BE%9B%E5%BA%94.png"
				alt="" />
			<div class="right-style flex justc" style="flex-wrap: wrap;height: 22vh;">
				<right-center ref="rightCenter" style="width: 100%;height: 100%;"></right-center>
			</div>
			<img class="title"
				src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/title/%E9%94%80%E5%94%AE%E5%88%86%E6%9E%90.png"
				alt="" />
			<div class="right-style flex justc" style="flex-wrap: wrap;height: 23.5vh;">
				<right-bottom ref="rightBottom" style="width: 100%;height: 100%;"></right-bottom>
			</div>
		</div>

		<div class="bottom flex alit justc">
			<div class="bottom-style">
				<div class="bottom-title">入驻服务</div>
				<bottom-left ref="bottomLeft" style="width: 100%;height: 100%;"></bottom-left>
			</div>
			<div class="bottom-style" style="margin: 0 20px;">
				<div class="bottom-title">营销服务</div>
				<bottom-center ref="bottomCenter" style="width: 100%;height: 100%;"></bottom-center>
			</div>
			<div class="bottom-style">
				<div class="bottom-title flex alit">协会服务 <img
						style="width: 20px;height: 20px;margin-left: 10px;cursor: pointer;"
						src="https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/%E5%88%97%E8%A1%A8.png"
						@click="dialogVisible3= true" />
				</div>
				<bottom-right ref="bottomRight" style="width: 100%;height: 100%;"></bottom-right>
			</div>
		</div>
	</div>

</template>

<script>
	import {
		Parser,
		Player
	} from 'svga'
	import finance from './finance.vue'
	import centerMap from './centerMap.vue'
	import rightTop from './homeRight/rightTop.vue'
	import rightCenter from './homeRight/rightCenter.vue'
	import rightBottom from './homeRight/rightBottom.vue'
	import bottomLeft from './homeBottom/bottomLeft.vue'
	import bottomCenter from './homeBottom/bottomCenter.vue'
	import bottomRight from './homeBottom/bottomRight.vue'
	import countTo from 'vue-count-to';
	import vueSeamlessScroll from 'vue-seamless-scroll'

	export default {
		components: {
			finance,
			centerMap,
			rightTop,
			rightCenter,
			rightBottom,
			bottomLeft,
			bottomCenter,
			bottomRight,
			countTo,
			vueSeamlessScroll
		},
		data() {
			return {
				oldShop: ['福州百洋海味食品有限公司', '梓潼县梓昌商贸有限责任公司', '重庆市武隆苕粉集团有限公司', '重庆市凯鸿食品有限公司', '重庆臻味鲜商贸有限公司',
					'陇南市武都区东赢农副产品有限责任公司', '重庆效禾食品有限公司', '重庆市好吃二农业开发有限公司', '克明五谷道场食品有限公司', '重庆千辣烩食品有限公司', '青岛康大食品有限公司',
					'阿斯特拉国际酒业贸易有限公司', '云南丝厨食品科技有限公司', '福建闽笋王食品有限公司'
				],
				newShop: ['重庆百连庆贸易有限公司', '重庆梓昌商贸有限公司', '重庆采葛记科技有限公司', '重庆渝凯鸿食品销售有限公司', '重庆臻味鲜商贸有限公司',
					'起元堂(重庆)供应链管理有限公司', '重庆火山焱商贸有限公司', '重庆市好吃二食品供应链有限公司', '爬山虎(重庆)供应链有限公司', '重庆千辣烩食品有限公司',
					'康大佳渝(重庆)食品有限公司',
					'阿斯特拉(重庆)国际酒业贸易有限公司', '重庆丝厨食品科技有限公司', '重庆闽笋王食品有限公司'
				],
				shopListInfo: [],
				rightTopIndex: 1,
				rightTopScreen: null,
				nowTime: new Date().getTime(),
				screenWidth: 0,
				screenHeight: 0,
				topData: {},
				leftOne: {},
				leftTwo: {},
				leftThree: {},
				leftFour: {},
				rightTop: {},
				interVal: '',
				timeOut: [],
				todayStatistics: null,
				// {
				// 	listOfOrders:[{
				// 		receivePhone:'12312323'
				// 	},{
				// 		receivePhone:'12312323'
				// 	},{
				// 		receivePhone:'12312323'
				// 	},{
				// 		receivePhone:'12312323'
				// 	},{
				// 		receivePhone:'12312323'
				// 	},{
				// 		receivePhone:'12312323'
				// 	},{
				// 		receivePhone:'12312323'
				// 	},{
				// 		receivePhone:'12312323'
				// 	},{
				// 		receivePhone:'12312323'
				// 	},{
				// 		receivePhone:'12312323'
				// 	},{
				// 		receivePhone:'12312323'
				// 	}]
				// }
				allStatistics: {},
				dialogVisible: false, //交易记录弹窗标记
				dialogVisible2: false,
				dialogVisible3: false,
				scrollOption: {
					direction: 1, //配置属性向下滚动
					limitMoveNum: 8,
					singleHeight: 70
				},
				scrollOption2: {
					direction: 1, //配置属性向下滚动
					limitMoveNum: 10,
					singleHeight: 40,
					waitTime: 3000
				}
			}
		},
		async created() {
			if (!sessionStorage.getItem('log')) {
				this.$message.error('请先登录');
				this.$router.replace('/login')
			}
			// 首页-中上-5个统计
			this.$request('/center/home/middleTop').then(res => {
				this.topData = res.data
			})
			// 首页-左1-产业概况
			this.$request('/center/home/leftOne').then(res => {
				this.leftOne = res.data
			})
			// 首页-左2-产业概况
			this.$request('/center/home/leftTwo').then(res => {
				this.leftTwo = res.data
			})
			// 首页-左3-供应链
			this.$request('/center/home/leftThree').then(res => {
				this.leftThree = res.data
			})
			// 首页-左4-源产地
			this.$request('/center/home/leftFour').then(res => {
				this.leftFour = res.data
			})
			this.$request('/center/home/rightOne').then(res => {
				this.rightTop = res.data
			})

			//中间上面统计数据(*今日)
			// this.$request('/center/home/statisticsAndTransactionList').then(res => {
			// 	this.todayStatistics = res.data
			// })
			let that = this
			// setInterval(() => {
			// 	that.$request('/center/home/statisticsAndTransactionList').then(res => {
			// 		that.todayStatistics = res.data
			// 	})
			// }, 10000);

			this.$request2('/admin-api/trade/order/realTimeTrading').then(res => {
				this.rightTopScreen = res.data
			})
			this.$request2('/admin-api/shop/info/list-center').then(res => {
				this.shopListInfo = res.data.reverse()
			})
			setInterval(() => {
				that.$request2('/admin-api/trade/order/realTimeTrading').then(res => {
					that.rightTopScreen = res.data
				})
			}, 10000);

			//中间上面统计数据(*全部)
			this.$request('/center/home/statisticsAndTransactionList?type=true').then(res => {
				this.allStatistics = res.data
			})
			setInterval(() => {
				this.$request('/center/home/statisticsAndTransactionList?type=true').then(res => {
					this.allStatistics = res.data
				})
			}, 10000);
			// this.screenWidth = window.screen.width;
			// this.screenHeight = window.screen.height;
			// const parser = new Parser()
			// const svga = await parser.load(
			// 	'https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/%E6%9C%8D%E5%8A%A1%E4%BA%BA%E6%95%B0icon.svga'
			// )
			// const player = new Player(document.getElementById('canvas'))
			// await player.mount(svga)
			// // 开始播放动画
			// player.start()
			that.restartCountTo1()
			that.restartCountTo2(15, 18, 20000)
			that.restartCountTo2(19, 24, 30000)
			that.restartCountTo2(25, 28, 40000)
			//1-5 为中间顶部  6-14  为产业概况  6 7要一起   15-18为标准建设  19-24为供应链  25-28为原产地
			let interVal = setInterval(function() {
				that.restartCountTo1()
				that.restartCountTo2(15, 18, 20000)
				that.restartCountTo2(19, 24, 30000)
				that.restartCountTo2(25, 28, 40000)
			}, 42000)

			let nowIndex = 0
			// setInterval(function() {
			// 	switch (nowIndex) {
			// 		case 0:


			// 			break;
			// 		default:
			// 			break;
			// 	}
			// }, 1000)

		},
		destroyed() {
			clearInterval(this.interVal)
			this.timeOut.forEach(timerId => {
				clearTimeout(timerId);
			});
		},
		methods: {
			changeShowText(e) {
				if (e.length <= 6) {
					//保留前1后1
					let start = e.substring(0, 1)
					let end = e.substring(e.length - 1, e.length)
					return start + '****' + end
				} else {
					// 前2后2
					let start = e.substring(0, 3)
					let end = e.substring(e.length - 2, e.length)
					return start + '****' + end
				}
			},
			getStatusType(status) {
				switch (status) {
					case 1:
						return '待付款';
					case 2:
						return '待发货';
					case 3:
						return '待收货';
					case 4:
						return '已完成';
					case 5:
						return '已取消';
					case 6:
						return '待成团';
					case 7:
						return '待售后';
					default:
						return '未知状态';
				}
			},
			handleButton(e) {
				console.log("点击行数据为：  ", JSON.stringify(e));
			},
			/**
			 * 将千克转换为万吨
			 * @param {number} kg - 需要转换的千克数
			 * @returns {number} - 转换后的万吨数
			 */
			kgToTons(kg) {
				// 1 吨 = 1000 千克
				// 1 万吨 = 10000 吨
				// 因此，1 万吨 = 1000 * 10000 千克 = 10000000 千克
				const KILOGRAMS_PER_TON = 10000000;
				return kg / KILOGRAMS_PER_TON;
			},
			/**
			 * 将千克转换为吨
			 * @param {number} kg - 需要转换的千克数
			 * @returns {number} - 转换后的吨数
			 */
			kgToTons1(kg) {
				// 1 吨 = 1000 千克
				const KILOGRAMS_PER_TON = 1000;
				return kg / KILOGRAMS_PER_TON;
			},
			rand(m, n) {
				return Math.ceil(Math.random() * (n - m + 1) + m - 1)
			},
			restartCountTo1() {
				let that = this
				setTimeout(function() {
					for (var i = 0; i < that.rand(3, 6); i++) {
						let countTo = 'countTo' + that.rand(6, 14)
						if (countTo == 'countTo6' || countTo == 'countTo7') {
							that.$refs.countTo6.start()
							that.$refs.countTo7.start()
						} else {
							that.$refs[countTo].start()
						}
					}
				}, 10000);
			},
			restartCountTo2(startIndex, endIndex, timeOut) {
				let that = this
				that.timeOut.push(setTimeout(function() {
					for (var i = startIndex; i <= endIndex; i++) {
						let countTo = 'countTo' + i
						console.log(countTo);
						that.$refs[countTo].start()
					}

					if (timeOut == 30000) {
						for (var i = 1; i <= 5; i++) {
							let countTo = 'countTo' + i
							console.log(that.$refs[countTo], countTo);
							that.$refs[countTo].start()
						}
					}
					if (timeOut == 40000) {
						that.timeOut = []
					}
				}, timeOut))
			}

		}
	}
</script>
<style>
	.scroll-time {
		background: rgb(20, 187, 130);
		border-radius: 30px;
		padding: 5px 10px;
		margin-left: 5px;
		width: 3.5vw;
		text-align: center;
		color: #fff;
	}

	.scroll-left {
		/* background: rgb(20, 187, 130); */
		width: 13.5vw;
		/* border-radius: 30px; */
		padding: 5px 20px;
		color: #fff;
		font-size: 12px;
	}

	.el-dialog {
		background-color: transparent !important;
	}

	.el-dialog__headerbtn {
		top: 12% !important;
		z-index: 999;
		right: 40px !important;
	}


	.top-count-title {
		font-weight: 900;
		font-size: 18px;
		color: #8AD4FF;
		font-style: italic;
	}

	.top-count-desc {
		font-weight: 500;
		font-size: 18px;
		color: #FFC300;
		margin-top: 6px;
	}

	.top-count-num {
		font-weight: 500;
		font-size: 28px;
		color: #FFC300;
	}

	.top-count {
		position: fixed;
		top: 11%;
		left: 0;
		width: 100%;
		z-index: 7;
	}

	.bottom-title {
		font-weight: 500;
		font-size: 16px;
		color: #FFFFFF;
		margin: 15px 0 0 20px;
	}

	.bottom-style {
		width: 17vw;
		height: 23vh;
		background: radial-gradient(41% 50% at 50% 50%, rgba(0, 126, 199, 0.01) 0%, rgba(0, 43, 161, 0.01) 99%);
		border-radius: 6px 6px 6px 6px;
		border: 1px solid;
		border-image: linear-gradient(270deg, rgba(42, 130, 228, 0.03), rgba(42, 130, 228, 0.01)) 1 1;
		/* background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/bottom/%E5%88%86%E7%BB%84%2017%403x.png'); */
		background-size: 100% 100%;
	}

	.bottom {
		width: 100vw;
		position: fixed;
		bottom: 20px;
	}

	.left-bottom-mu {
		font-weight: 500;
		font-size: 12px;
		color: #FFC300;
		margin-top: 4px;
	}

	.left-bottom-num {
		font-weight: 500;
		font-size: 20px;
		color: #FFC300;
		width: 4vw;
		text-align: right;
	}

	.left-bottom-title {
		font-weight: bold;
		font-size: 12px;
		color: #FFFFFF;
		margin-right: 15px;
	}

	.left-bottom {
		width: 175px;
		height: 60px;
		background-size: 100% 100%;
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/%E5%88%86%E7%BB%84%206%402x.png');
	}

	.left-one-list-title {
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: bold;
		font-size: 10px;
		color: #FFFFFF;
		text-align: center;
		line-height: 16px;
	}

	.left-one-list {
		width: 80px;
		height: 80px;
		/* background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/left/dizuo22.png');
		background-size: 70% 70%;
		background-repeat: no-repeat;
		background-position: 50% 80%; */
		position: relative;
	}

	.left-style {
		width: 20.8vw;
		background: radial-gradient(41% 50% at 50% 50%, rgba(0, 126, 199, 0.01) 0%, rgba(0, 43, 161, 0.01) 99%);
		padding: 2% 0;

		border-radius: 4px;
		/* border: 1px solid #2A82E4; */
		margin-top: 10px;
		margin-bottom: 10px;
		border: 1px solid;
		border-image: linear-gradient(270deg, rgba(42, 130, 228, 0.03), rgba(42, 130, 228, 0.01)) 1 1;
		/* border-image: linear-gradient(270deg, rgba(42, 130, 228, 1), rgba(42, 130, 228, 0.6)) 1 1; */
	}

	.right-style {
		width: 20.8vw;
		background: radial-gradient(41% 50% at 50% 50%, rgba(0, 126, 199, 0.01) 0%, rgba(0, 43, 161, 0.01) 99%);
		padding: 2% 0;

		border-radius: 4px;
		border: 1px solid #2A82E4;
		margin-top: 10px;
		margin-bottom: 10px;
		border: 1px solid;
		border-image: linear-gradient(270deg, rgba(42, 130, 228, 0.03), rgba(42, 130, 228, 0.01)) 1 1;

	}

	.title {
		width: 20.8vw;
		height: 4vh;
		/* background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/%E6%A0%87%E9%A2%98.png');
		background-size: 100% 100%;
		
		font-weight: 900;
		font-size: 20px;
		color: #FFFFFF;
		line-height: 3.3vh;
		letter-spacing: 2px;
		text-align: left;
		font-style: normal;
		text-transform: none; */

	}

	.left {
		position: fixed;
		left: 20px;
		top: 7vh;
		z-index: 8;
	}

	.right {
		position: fixed;
		right: 20px;
		top: 7vh;
		z-index: 999;
	}

	.centerbg {
		width: 1200px;
		height: 900px;
		position: relative;
		/* background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/bg%E5%8A%A8%E7%94%BB.png');
		background-size: 100% 100%; */
	}

	.cont {
		position: fixed;
		width: 100%;
		height: 100%;
		/* background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/appVersion/Rectangle_5893%20%281%29.png');
		background-size: 100% 100%; */
		background: #001b39;
	}

	.top-title {
		background-image: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/hotpot/brain/top-title3.png');
		background-size: 90% 90%;
		background-repeat: no-repeat;
		position: fixed;
		background-position: center;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100px;
		z-index: 99;
	}
</style>

<style scoped lang="scss">
	.trtdstyle {
		display: flex;

		>td,
		>th {
			&:nth-child(1) {
				flex: 1;
			}

			&:nth-child(2) {
				flex: 1.1;
			}

			&:nth-child(6) {
				flex: 1.8;
			}

			&:nth-child(4) {
				flex: 0.5;
			}

			&:nth-child(3) {
				flex: 1.5;
			}



			&:nth-child(5) {
				flex: 0.5;
			}
		}
	}

	.mySeamlessScrollStyle {
		padding: 80px;
		background: url('https://qgst.obs.cn-southwest-2.myhuaweicloud.com/rabbit/standardizedAquaculture/popup/bg%402x.png') center center / 100% 100% no-repeat;
		background-color: transparent;
		position: relative;
		opacity: 0.9;
		font-size: 17px;
		height: 60vh;

		//滚动容器大小
		.scrollStyle {
			width: 100%;
			height: 100%;
			overflow: hidden; //必须
		}

		//h5表格样式
		.customTable {
			width: 100%;


			table {
				width: 80%;
				margin: 20px 0;
				border-collapse: collapse;
				box-shadow: 0 2px 20px rgba(0, 150, 255, 0.3);
				background-color: rgba(0, 43, 161, 0.3);
				// border: 1px solid #0096ff;
			}

			th,
			td {
				padding: 20px;
				text-align: center;
				// border: 1px solid #0096ff;
			}

			th {
				background-color: #0096ff;
				color: #dfdfdf;
				text-transform: uppercase;
			}

			tr {
				color: #dfdfdf;
			}

			tr:nth-child(even) {
				background-color: rgba(17, 34, 64, 0.2);
			}

			tr:hover {
				background-color: #0096ff;
				color: #FFFFFF;
			}

			thead {
				border-bottom: 2px solid #0096ff;
			}
		}

	}
</style>