export default {
	formatCurrency(num) {
		//数字金额按每三位数字加逗号金额必须为带分  12300 00代表到分
		num = (num / 100).toFixed(2)
		return num.replace(/\d(?=(\d{3})+\.)/g, '$&,');
	},
	formatCurrency2(num) {
		//数字金额按每三位数字加逗号金额不带分  123
		var result = [],
			counter = 0;
		num = (num || 0).toString().split('');
		for (var i = num.length - 1; i >= 0; i--) {
			counter++;
			result.unshift(num[i]);
			if (!(counter % 3) && i != 0) {
				result.unshift(',');
			}
		}
		return result.join('');
	},
	//时间戳转换为 YYYY-MM-DD hh:ss:mm
	changeTime(e) {
		let date = new Date(e)
		let year = date.getFullYear()
		let month = date.getMonth() + 1
		let day = date.getDate()
		month = month < 10 ? '0' + month : month
		day = day < 10 ? '0' + day : day
		let hour = date.getHours()
		let secends = date.getSeconds()
		let mins = date.getMinutes()
		hour = hour < 10 ? '0' + hour : hour
		secends = secends < 10 ? '0' + secends : secends
		mins = mins < 10 ? '0' + mins : mins
		return year + '-' + month + '-' + day + ' ' + hour + ':' + mins + ':' + secends
	}
}